import { createSelector } from "@reduxjs/toolkit";
import { IStoreState } from "../../../../reducers/types";
import { CallState } from "../../../rewards/reducer";

export const getFlightShopCallState = (state: IStoreState) =>
  state.packagesFlightShop.packagesFlightShopCallState;

export const getFlightShopCallResults = (state: IStoreState) =>
  state.packagesFlightShop.packagesFlightShopResults;

export const getAirShopSummary = createSelector(
  getFlightShopCallResults,
  (flightShopCallResults) => {
    return flightShopCallResults?.airShopSummary.value;
  }
);

export const getFlightsToRender = createSelector(
  getAirShopSummary,
  (airShopSummary) => {
    return airShopSummary.flights;
  }
);

export const getPackagesByOutboundFareSlice = createSelector(
  getFlightShopCallResults,
  (flightShopCallResults) => {
    return flightShopCallResults?.packagesByOutboundFareSlice;
  }
);

export const getIsFlightShopLoading = createSelector(
  getFlightShopCallState,
  (flightShopCallState) => {
    return flightShopCallState === CallState.InProcess;
  }
);
