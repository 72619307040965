import { CallState, ShopFlightsResponse } from "redmond";
import { actions, actionTypes } from "../actions";

export interface IPackagesFlightShopState {
  packagesFlightShopCallState: CallState;
  // TODO: Change this to use the correct model from airShopSummary
  packagesFlightShopResults: ShopFlightsResponse | null;
}

const initialState: IPackagesFlightShopState = {
  packagesFlightShopCallState: CallState.NotCalled,
  packagesFlightShopResults: null,
};

export const reducer = (
  state: IPackagesFlightShopState = initialState,
  action: actions.PackagesFlightShopActions
): IPackagesFlightShopState => {
  switch (action.type) {
    case actionTypes.FETCH_PACKAGE_FLIGHT_SHOP:
      return {
        ...state,
        packagesFlightShopCallState: CallState.InProcess,
      };
    case actionTypes.SET_PACKAGE_FLIGHT_SHOP_RESULTS: {
      return {
        ...state,
        packagesFlightShopCallState: action.flightShopCallState,
        packagesFlightShopResults: action.flightShopResults,
      };
    }
    case actionTypes.SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED:
      return {
        ...state,
        packagesFlightShopCallState: CallState.Failed,
      };

    default:
      return { ...state };
  }
};

export * from "./selectors";
