import React, { useContext, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { IDesktopFlightShopProps } from "./container";
import clsx from "clsx";
import "./styles.scss";
import {
  Header,
  B2BLoadingPopup,
  FlightSearchLoadingImage,
  useDeviceTypes,
  B2BSpinner,
} from "halifax";
import { ClientContext } from "../../../../App";
import { RewardsAccountSelection } from "../../../rewards/components";
import { PATH_HOME } from "../../../../utils/paths";
import {
  PackagesFlightShopStep,
  Slice,
  IFlightListData,
  FareclassOptionFilter,
} from "redmond";
import { FlightListInfo } from "./components/FlightListInfo";
import { FlightCardType } from "./components/FlightListInfo/component";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  CHOOSING_FLIGHT_TEXT,
  INITIAL_RESULT_SET_SIZE,
  RECOMMENDED_FLIGHT_LIST_SEPARATOR_PRIMARY_TEXT,
  RECOMMENDED_FLIGHT_LIST_SEPARATOR_SECONDARY_TEXT,
  REWARDS_BANNER_TEXT,
  SEARCHING_FOR_FLIGHTS,
  SHOW_MORE_NUM,
} from "./components/FlightListInfo/textConstants";
import { PackagesShopProgressBar } from "../../../book/components";
import { TravelWalletDrawer } from "../../../travel-wallet/components";

export const EARN_ENHANCEMENT_SUBTITLE = (
  earnRate: string | number,
  productDisplayName: string
) => (
  <>
    <span className="font-bold">Earn {earnRate}X miles</span> on flights with
    your {productDisplayName} account.
  </>
);

export const DesktopFlightShop = (props: IDesktopFlightShopProps) => {
  const {
    history,
    isFlightShopLoading,
    fetchPackagesFlightShop,
    airShopSummary,
    packagesByOutboundFareSlice,
    rewardsKey,
    largestValueAccount,
    origin,
    destination,
  } = props;

  const clientContext = useContext(ClientContext);
  const { logo, isAgentPortal } = clientContext;

  const { matchesDesktop, matchesLargeDesktop } = useDeviceTypes();

  // TODO: Change this to use correct type
  const [faresToShow, setFaresToShow] = useState<any[]>([]);
  // const [expandedFlight, setExpandedFlight] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPackagesFlightShop(history);
  }, []);

  // TODO: this should be replaced with actual filters
  const fareClassFilter: FareclassOptionFilter = {
    basic: false,
    standard: false,
    enhanced: false,
    premium: false,
    luxury: false,
  };

  const renderRecommendedFlightInfo = (outbound: any) => {
    const flightSliceId = outbound.slice;
    const flightSlice = airShopSummary.flights?.slices[flightSliceId];
    if (!flightSlice) {
      return null;
    }
    return (
      <>
        {renderFlightListInfo(
          outbound.fares[0],
          true,
          flightSlice,
          outbound,
          0,
          true
        )}
        {/* TODO: Move this to components, make common */}
        <Box className={clsx("recommended-flight-list-separator")}>
          <Box className="recommended-flight-list-separator-top-row">
            <Box className="dashed-line" />
            <Typography className="recommended-flight-list-separator-primary-text">
              {RECOMMENDED_FLIGHT_LIST_SEPARATOR_PRIMARY_TEXT}
            </Typography>
            <Box className="dashed-line" />
          </Box>
          <Typography className="recommended-flight-list-separator-secondary-text">
            {RECOMMENDED_FLIGHT_LIST_SEPARATOR_SECONDARY_TEXT}
          </Typography>
        </Box>
      </>
    );
  };

  const renderFlightListInfo = (
    selectedFare: any,
    showListView: boolean,
    slice: Slice,
    flight: IFlightListData,
    index: number,
    recommendedFlight?: boolean
  ) => {
    const fareId = selectedFare.example?.fare || selectedFare.id;
    return (
      <Box
        id={fareId}
        className={clsx(
          "flight-list-item",
          `flight-list-item-${index}`,
          "flight-row",
          {
            "row-view-desktop":
              matchesDesktop || (matchesLargeDesktop && showListView),
            // expanded: fareId === expandedFlight,
          },
          "b2b"
        )}
        key={fareId}
      >
        <FlightListInfo
          type={FlightCardType.content}
          selectedFare
          slice={slice}
          flights={airShopSummary.flights}
          flight={flight}
          rewardsKey={rewardsKey || ""}
          fareClassFilter={fareClassFilter}
          // TODO: use real params here
          onAlgomerchClick={() => {}}
          maxFlightPriceFilter={10000}
          isRoundTrip={true}
          isOutgoing={true}
          isAgentPortal={isAgentPortal}
          packagesByFareSlice={packagesByOutboundFareSlice}
          recommendedFlight={recommendedFlight}
        />
      </Box>
    );
  };

  useEffect(() => {
    if (airShopSummary && airShopSummary.flights.outbound.length > 0) {
      setFaresToShow(
        airShopSummary.flights.outbound.slice(0, INITIAL_RESULT_SET_SIZE)
      );
      // setExpandedFlight("");
    } else {
      setFaresToShow([]);
    }
    return clearTimeout(setFetchMoreData());
  }, [airShopSummary]);

  useEffect(() => {
    if (isFlightShopLoading) setFaresToShow([]);
  }, [isFlightShopLoading]);

  const setFetchMoreData = () => {
    const newPageSize = faresToShow.length + SHOW_MORE_NUM;
    return setTimeout(
      () =>
        setFaresToShow(airShopSummary.flights.outbound.slice(0, newPageSize)),
      500
    );
  };

  return (
    <Box className={clsx("packages-flight-list-root")}>
      <Box className={clsx("packages-flight-list-container")}>
        <Header
          // TODO: Make this common component to be used throughout Packages Funnel?
          className="rewards-components-section"
          left={
            <Box className={"rewards-account-section-left-content"}>
              <Box className={"logo"} onClick={() => history.push(PATH_HOME)}>
                {logo}
              </Box>
              {origin && destination && (
                <Box className={"rewards-account-section-travel-details"}>
                  <Typography variant={"body1"} tabIndex={0}>
                    {CHOOSING_FLIGHT_TEXT(
                      // TODO: Change this to use real params
                      PackagesFlightShopStep.ChooseDeparture,
                      origin.label,
                      destination.label
                    )}
                  </Typography>
                  <Typography variant={"body2"}>
                    {REWARDS_BANNER_TEXT}
                  </Typography>
                </Box>
              )}
            </Box>
          }
          right={
            <Box className="desktop-flight-list-rewards-account-contents">
              <RewardsAccountSelection
                className={clsx("b2b hide-balance-border")}
                popoverClassName="b2b"
              />
              <TravelWalletDrawer />
            </Box>
          }
        ></Header>
        <PackagesShopProgressBar />
        <Box className="packages-flight-list">
          {isFlightShopLoading && (
            <B2BLoadingPopup
              open={isFlightShopLoading}
              message={SEARCHING_FOR_FLIGHTS}
              secondaryMessage={EARN_ENHANCEMENT_SUBTITLE(
                largestValueAccount.earn.flightsMultiplier,
                largestValueAccount.productDisplayName
              )}
              image={FlightSearchLoadingImage ?? ""}
              className="flight-list-loading-popup"
              popupSize={"desktop"}
            />
          )}
          {!isFlightShopLoading &&
            airShopSummary &&
            packagesByOutboundFareSlice &&
            faresToShow.length > 0 && (
              <InfiniteScroll
                dataLength={faresToShow.length}
                next={setFetchMoreData}
                hasMore={
                  faresToShow.length < airShopSummary.flights.outbound.length
                }
                loader={
                  <Box className="loading-flights">
                    <B2BSpinner classes={["loading-flights-bunny"]} />
                  </Box>
                }
              >
                {/* TODO: This should use actual recommended flight once we're unblocked */}
                {renderRecommendedFlightInfo(faresToShow[0])}
                {faresToShow.map((outbound: any, index: number) => {
                  // TODO: Filter for not recommended flight
                  const flightSliceId = outbound.slice;
                  const flightSlice =
                    airShopSummary.flights?.slices[flightSliceId];
                  if (!flightSlice) {
                    return null;
                  }
                  // TODO: Use real params
                  const isListView = true;
                  return renderFlightListInfo(
                    outbound.fares[0],
                    isListView,
                    flightSlice,
                    outbound,
                    index
                  );
                })}
              </InfiniteScroll>
            )}
        </Box>
      </Box>
    </Box>
  );
};
