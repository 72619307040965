import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";

import { PackagesFlightShop } from "./component";

const mapStateToProps = (_: IStoreState) => {
  return {};
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PackagesFlightShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPackagesFlightShop = withRouter(
  connector(PackagesFlightShop)
);
