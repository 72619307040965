export const FETCH_PACKAGE_FLIGHT_SHOP =
  "packagesFlightShop/FETCH_PACKAGE_FLIGHT_SHOP";
export type FETCH_PACKAGE_FLIGHT_SHOP = typeof FETCH_PACKAGE_FLIGHT_SHOP;

export const SET_PACKAGE_FLIGHT_SHOP_RESULTS =
  "packagesFlightShop/SET_PACKAGE_FLIGHT_SHOP_RESULTS";
export type SET_PACKAGE_FLIGHT_SHOP_RESULTS =
  typeof SET_PACKAGE_FLIGHT_SHOP_RESULTS;

export const SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED =
  "packagesFlightShop/SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED";
export type SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED =
  typeof SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED;
