import { CallState, ShopFlightsResponse } from "redmond";
import * as actionTypes from "./constants";
import * as H from "history";

export interface IFetchPackagesFlightShop {
  type: actionTypes.FETCH_PACKAGE_FLIGHT_SHOP;
  history: H.History;
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallPackageFlightAvailability?: boolean;
  };
}

export const fetchPackagesFlightShop = (
  history: H.History,
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallPackageFlightAvailability?: boolean;
  }
): IFetchPackagesFlightShop => ({
  type: actionTypes.FETCH_PACKAGE_FLIGHT_SHOP,
  history,
  options,
});

export interface ISetPackagesFlightShopResults {
  type: actionTypes.SET_PACKAGE_FLIGHT_SHOP_RESULTS;
  flightShopCallState: CallState;
  // TODO: Change this to use the correct model from airShopSummary
  flightShopResults: ShopFlightsResponse;
}

export const setPackagesFlightShopResults = (args: {
  flightShopCallState: CallState;
  // TODO: Change this to use the correct model from airShopSummary
  flightShopResults: ShopFlightsResponse;
}): ISetPackagesFlightShopResults => ({
  type: actionTypes.SET_PACKAGE_FLIGHT_SHOP_RESULTS,
  flightShopCallState: args.flightShopCallState,
  flightShopResults: args.flightShopResults,
});

export interface ISetPackagesFlightShopCallStateFailed {
  type: actionTypes.SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED;
  flightShopCallError: any; // Change this to correct error type
}

export const setPackagesFlightShopCallStateFailed = (
  flightShopCallError: any // Change this to correct error type
): ISetPackagesFlightShopCallStateFailed => ({
  type: actionTypes.SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED,
  flightShopCallError,
});

export type PackagesFlightShopActions =
  | IFetchPackagesFlightShop
  | ISetPackagesFlightShopResults
  | ISetPackagesFlightShopCallStateFailed;
