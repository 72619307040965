import { Box } from "@material-ui/core";
import React from "react";
import { PackagesFlightShopConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import { useDeviceTypes } from "halifax";
import { DesktopFlightShop } from "./components/DesktopFlightShop";

export interface IPackagesFlightShopProps
  extends PackagesFlightShopConnectorProps,
    RouteComponentProps {}

export const PackagesFlightShop = (_: IPackagesFlightShopProps) => {
  const { matchesDesktop } = useDeviceTypes();
  return (
    <Box className={"packages-flight-shop-root"}>
      {matchesDesktop && <DesktopFlightShop />}
    </Box>
  );
};
